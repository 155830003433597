import(/* webpackChunkName: "env" */ "./settings/env").then(env => {
  // if (process.env.NODE_ENV === "production" && !process.env.VUE_APP_SERVER_URL) {
    env.getProductionConfig();
  // }

  if (process.env.VUE_APP_CORDOVA_PLATFORM) {
    import(/* webpackChunkName: "cordova-config" */ "@/settings/cordova");
  }
  import(/* webpackChunkName: "preconfigured" */ "./preconfiguredApp").then(app => {
    app.createAppInstance();
  });
});
